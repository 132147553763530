@font-face {
  font-family: Inter;
  src: url("/assets/fonts/Inter-Regular.woff2") format("woff2"),
    url("/assets/fonts/Inter-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: url("/assets/fonts/Inter-Medium.woff2") format("woff2"),
    url("/assets/fonts/Inter-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: url("/assets/fonts/Inter-SemiBold.woff2") format("woff2"),
    url("/assets/fonts/Inter-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Inter;
  src: url("/assets/fonts/Inter-Bold.woff2") format("woff2"),
    url("/assets/fonts/Inter-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TT Interfaces";
  src: url("/assets/fonts/TTInterfaces-Bold.woff2") format("woff2"),
    url("/assets/fonts/TTInterfaces-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TT Interfaces";
  src: url("/assets/fonts/TTInterfaces-Regular.woff2") format("woff2"),
    url("/assets/fonts/TTInterfaces-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
