@import "normalize";
@import "colors";
@import "fonts";
@import "vendors/nprogress";
@import "swiper/scss";
@import "styles/utils/mixins";

body {
  position: relative;
}

.is-frozen {
  width: 100%;
  height: 100%;

  &::after {
    content: "";
    position: absolute;
    top: 90px;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(24 22 44 / 80%);
    opacity: 0;
    transition: all 0.4s;
    pointer-events: none;
  }
}

img {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  height: auto;
}

.layer {
  &::after {
    opacity: 1;
    z-index: 2;
  }
}
