:root {
  --native-icon-fill: #000;
  --native-icon-secondary-fill: #fff;
  --brand-color-key: #ce1050;
  --text-color-light: #fff;
  --text-color-dark: #18162c;
  --text-color-grey: #8b8b95;
  --text-color-blue: #012f6b;
  --text-color-brand: var(--brand-color-key);
}
